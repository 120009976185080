import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
import Figure from 'components/content/Figure';
export const _frontmatter = {
  "path": "/developer/react-flux",
  "date": "2015-09-09",
  "title": "REACT & FLUX",
  "author": "admin",
  "tags": ["development", "javascript", "raect.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Flux is the application architecture that Facebook uses for building client-side web applications. It complements React’s composable view components by utilizing a unidirectional data flow. It’s more of a pattern rather than a formal framework."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`React components`}</h2>
    <p>{`With ReactJS, you pass properties, and it returns with the rendered DOM. If you pass different data, you will get different responses. This makes them extremely reusable and handy to combine them into an application.`}</p>
    <h2>{`Top-down rendering`}</h2>
    <p>{`But an application doesn't make much sense without data. When we build a ReactJS app, we start with a root node that takes data as a parameter and pass down its component hierarchy (ie. child components of the root).`}</p>
    <p>{`This feature is called `}<inlineCode parentName="p">{`top-down rendering`}</inlineCode>{`.`}</p>
    <h3>{`Props`}</h3>
    <p>{`ReactJS properties or `}<strong parentName="p">{`props`}</strong>{` object is what gets passed down the component hierarchy.`}</p>
    <h3>{`State`}</h3>
    <p>{`But what if we want to notify the components at a higher level in the hierarchy if something has changed? For instance a button click.`}</p>
    <p>{`We need something that stores the actual state of our application, something that we can notify if the state should change. The new state should be passed to the root node, and the top-down rendering should be kicked in again to generate (re-render) the new output (DOM) of our application. This is where `}<inlineCode parentName="p">{`Flux`}</inlineCode>{` comes into the picture.`}</p>
    <h2>{`Why Flux?`}</h2>
    <p>{`Flux tries to avoid the complex cross dependencies between your modules (MVC for example) and realize a simple `}<inlineCode parentName="p">{`one-way data flow`}</inlineCode>{`. This helps you to write scalable applications and avoid side effects in your application.`}</p>
    <p>{`Flux has four main components:`}</p>
    <ul>
      <li parentName="ul">{`Dispatcher`}</li>
      <li parentName="ul">{`Stores`}</li>
      <li parentName="ul">{`Actions`}</li>
      <li parentName="ul">{`Views`}</li>
    </ul>
    <p>{`Below is a diagram showing the components of Flux:`}</p>
    <Figure src="https://facebook.github.io/flux/img/overview/flux-simple-f8-diagram-explained-1300w.png" alt="Flux" figcaption="Fig. 1 - A view of Flux components and description." width={800} mdxType="Figure" />
    <h2>{`Dispatcher`}</h2>
    <p>{`According to Facebook, the dispatcher is the `}<inlineCode parentName="p">{`central hub`}</inlineCode>{` that manages all data flow in a Flux application.`}</p>
    <p>{`It acts as a registry of callbacks into the stores.`}</p>
    <p>{`Each store registers itself with the dispatcher and provides a callback routine.`}</p>
    <h2>{`Stores`}</h2>
    <p>{`Stores contain the application state and logic.`}</p>
    <p>{`Every Store receives every action from the Dispatcher but a single store handles only the specified events. For example, the User store handles only user specific actions like `}<strong parentName="p">{`createUser`}</strong>{` and avoid the other actions.`}</p>
    <p>{`After the store handled the Action and it's updated, the Store broadcasts a change event. This event will be received by the Views.`}</p>
    <p>{`Store shouldn't be updated externally, the update of the Store should be triggered internally as a response to an Action: `}<inlineCode parentName="p">{`Inversion of Control`}</inlineCode>{`.`}</p>
    <h2>{`Actions`}</h2>
    <p>{`Actions can have a `}<inlineCode parentName="p">{`type`}</inlineCode>{` and a `}<inlineCode parentName="p">{`payload`}</inlineCode>{`. They can be triggered by the Views or by the Server (external source). Actions trigger Store updates.`}</p>
    <p>{`Actions should be descriptive, and should not perform an other action - no cascading actions.`}</p>
    <h2>{`Views`}</h2>
    <p>{`Views are subscribed for one or multiple Stores and handle the store `}<strong parentName="p">{`change`}</strong>{` event.`}</p>
    <p>{`When a store `}<strong parentName="p">{`change`}</strong>{` event is received, the view will get the data from the Store via the Store's getter functions. Then the View will render with the new data.`}</p>
    <p>{`Here are the steps:`}</p>
    <ul>
      <li parentName="ul">{`Store change event received`}</li>
      <li parentName="ul">{`Get data from the Store via getters`}</li>
      <li parentName="ul">{`Render view`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      